ng-dropdown-panel {
  -webkit-overflow-scrolling: unset !important;
  border-radius: $input-border-radius;
  z-index: 1025!important;
  position: absolute !important;

  .ng-option {
    padding: ($input-padding-y * 2.1) ($input-padding-x);

    transition: 50ms linear all;

    @include media-breakpoint-up(xl) {
      padding: ($input-padding-y) ($input-padding-x);
    }

    @include media-breakpoint-down(lg) {
      &:active {
        background-color: rgba(lighten($primary, 5), 0.2);
      }
    }

    i.checked{
      display: none;
    }

    &.ng-option-marked {
      background-color: rgba(lighten($primary, 5), 0.2);
    }

    &.ng-option-selected {
      background-color: $primary;
      color: #FFF!important;

      i.checked {
        color: #FFF;
        display: inline-block;
      }

      i.unchecked{
        display: none;
      }

      &.ng-option-marked {
        background-color: darken($primary, 10);
      }

    }

    &.ng-option-disabled {
      opacity: 0.5;
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .ng-optgroup {
    font-weight: 700;

    padding: ($input-padding-y * 1.7) ($input-padding-x);
    @include media-breakpoint-up(xl) {
      padding: ($input-padding-y) ($input-padding-x);
    }
  }

  .ng-dropdown-header {
    .header-container {
      padding: $input-padding-y $input-padding-x;
      border-bottom: 1px solid $input-border-color;

      input {
        &::placeholder {
          text-transform: capitalize;
        }
      }

      .small-search {
        border: none;
        outline: none!important;
      }
    }
    @include media-breakpoint-down(lg) {
      input {
        padding: ($input-padding-y * 1.6) ($input-padding-x);
      }
    }

  }

  .ng-dropdown-footer {
    padding: $input-padding-y $input-padding-x;
    border-top: 1px solid $input-border-color;
  }

  .total-padding {
    display: block !important;
    position: relative !important;
  }

  .scroll-host {
    -webkit-overflow-scrolling: unset !important;
    overflow: auto !important;
  }

  @include media-breakpoint-up(xl) {
    border: 1px solid $input-border-color;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.35);
    background-color: $content-bg;
    left: -1px;
    //margin-top: 4px;
    width: calc(100% + 2px) !important;
    min-width: 320px !important;
    top: 32px !important;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    .scroll-host {
      max-height: 200px !important;
    }
    .ng-dropdown-footer {
      .btn {
        padding: 0.2em 0.5em;
        font-size: 90%;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    z-index: 100025!important;
    position: fixed!important;
    max-height: calc(100% - 140px) !important;
    height: auto;
    width: calc(100% - 20px) !important;
    max-width: 480px;
    left: 0;
    right: 0;
    top: 70px;

    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;

    @media screen and ( max-height: 500px ){
      top: 10px;
      max-height: calc(100% - 20px) !important;
    }

    &::before {
      content: ' ';
      position: fixed;
      //pointer-events: none;
      height: 100vh;
      width: 100vw;
      z-index: 0;
      background-color: rgba(0, 0, 0, 0.70);
      top: 0;
      left: 0;
    }

    .ng-dropdown-header, .ng-dropdown-panel-items, .ng-dropdown-footer {
      z-index: 2;
      position: relative;
      background-color: $content-bg;
    }

    .ng-dropdown-header {
      flex-shrink: 0;
      border-top-left-radius: $input-border-radius;
      border-top-right-radius: $input-border-radius;
    }
    .ng-dropdown-footer {
      border-bottom-left-radius: $input-border-radius;
      border-bottom-right-radius: $input-border-radius;
    }
    .ng-dropdown-panel-items {
      max-height: 500px!important;
    }
  }
}

ng-select {
  position: relative;
  padding: 0!important;

  &.dropdown-toggle {
    &:after {
      position: absolute;
      right: 10px;
      top: calc(50% - 2px);
    }

    .ng-clear-wrapper {
      margin-right: 10px;
      .ng-clear {
        margin-top: 3px;
      }
    }
  }

  &.ng-select-filtered .ng-placeholder {
    opacity: 0;
    display: block!important;
  }

  .ng-has-value .ng-placeholder {
    display: none!important;
  }

  .ng-placeholder {
    padding: $input-padding-y $input-padding-x!important;
    color: $input-placeholder-color;
  }

  &.ng-select-disabled {
    background-color: $input-disabled-bg;
  }

  .ng-clear-wrapper, .ng-value-icon {
    display: none;
  }

  .ng-input > input {
    padding: $input-padding-y 0!important;
  }

  .ng-value {
    padding: $input-padding-y $input-padding-x!important;
  }

  .ng-value-container {
    flex-wrap: nowrap!important;
    overflow: hidden;
    text-overflow: ellipsis;
    > .ng-value {
      visibility: revert!important;
      //margin-right: 1px;
      .ng-value-icon {
        padding: 0 5px;
      }
    }

    > .ng-input > input{
      //visibility: hidden;
      opacity: 0;
    }
  }

}
