@import '~src/scss/bootstrap_base';

$sidebar-width: 280px;
$sidebar-width-minimised: 68px;
$sidebar-menu-btn-colour: #ffffff;
$sidebar-divider: $sidebar-selected;

.sidebar-dim {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #292929;
  opacity: 0.4;
}

.sidebar {
  height: 100%;
  position: relative;
  overflow: hidden;
  width: 0;
  padding: 0;

  .sidebar-nav{
    color: #292929;
    background: $sidebar-bg;

    transition: all .1s linear;
    transform: translateZ(0);

    width: $sidebar-width;
    position: fixed;
    height: calc(100vh - 60px);
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    z-index: 100;

    >ul, .nav-children {
      display: flex;
      list-style: none;
      width: 100%;
      flex-direction: column;
      min-height: 100%;
      padding: 0;
      margin: 0;
    }
  }

  &.offline .sidebar-nav {
    height: calc(100vh - 80px);
  }

  .nav-title  {
    padding: 0.75rem 1rem 0.1rem;
    font-size: 95%;
    font-weight: 500;
    width: $sidebar-width;
  }

  .nav-divider {
    padding: 0;
    margin: 0.4rem 1rem;
    border-bottom: 2px solid $sidebar-divider;
  }

  .nav-item {
    position: relative;
    margin: 0;
    width: $sidebar-width;

    .nav-children {
      display: none;
      border-left: 5px $primary solid;

      > li > a > .text {
        font-size: 90%;
        font-weight: 300;
      }
    }

    &.active > .nav-children, &.expanded > .nav-children {
      display: block;
    }

    .nav-link{
      display: flex;
      align-items: center;
      padding: 0.5rem 0.85rem;
      color: $sidebar-highlight;
      text-decoration: none;
      background: transparent;
      cursor: pointer;

      line-height: 1.3;

      .text{
        font-weight: 500;
      }

      .fa {
        transition: all .1s linear;
        font-size: 2rem;
        transform-origin: center;
        transform: scale(0.65);
        margin-right: 10px;
      }

      &:hover{
        background: darken($sidebar-bg, 4);
      }

      &.active{
        background-color: $sidebar-selected-bg;
        color: $sidebar-selected;
      }

    }
  }

  .logo {
    padding: 20px;
    img {
      max-width: 180px;
      width: 180px;
    }

    background-color: #FFF;
    transition: 150ms linear opacity!important;
  }

  @include media-breakpoint-up(sm){
    width: $sidebar-width-minimised;
    .sm-menu-button {
      display: none;
    }
    .sidebar-nav{
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    }
  }

  @include media-breakpoint-up(xl){
    width: $sidebar-width;
    transition: all .1s linear;

    &.toggle-large {
      width: $sidebar-width-minimised;

      .sidebar-nav {
        width: $sidebar-width-minimised;
      }

      .logo{
        opacity: 0;
      }

      .nav-link {
        .text {
          opacity: 0;
        }

        .fa {
          transform: none;
        }

        .badge {
          display: none;
        }
      }

      .menu-toggle .nav-link .fa {
        transform: rotate(180deg);
      }
    }
  }

  @include media-breakpoint-between(sm, lg) {
    &:not(.toggle) {
      width: $sidebar-width-minimised;

      .sidebar-nav {
        width: $sidebar-width-minimised;
      }

      .logo{
        opacity: 0;
      }

      .nav-link {
        .text {
          opacity: 0;
        }

        .fa {
          transform: none;
        }

        .badge {
          display: none;
        }
      }

      .menu-toggle .nav-link .fa {
        transform: rotate(180deg);
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .sm-menu-button{
      color: $sidebar-menu-btn-colour;
      position: fixed;
      top: 7px;
      left: 5px;
      z-index: 1050;
      font-size: 1.4rem;
      box-shadow: none!important;
    }

    .sidebar-nav{
      margin-left: -$sidebar-width;
    }

    &.toggle{
      .sidebar-nav{
        margin-left: 0;
      }
    }

  }
}
