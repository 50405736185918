@import '~src/scss/bootstrap_base';

@media print {
  app-filter, .sidebar, .nav-tabs{
    display: none!important;
  }

  .content-container {
    padding: 0!important;
  }
}
